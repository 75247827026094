<template>
  <component :is="icon" :style="style" class="flex-shrink-0" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  name: string
  size?: number
}>()

const icon = computed<unknown>(() => {
  const icons = import.meta.glob('/assets/icons/*.svg', { eager: true, query: 'component' })
  const icon = icons[`/assets/icons/${props.name}.svg`]
  if (!icon) console.error(`No such icon ${props.name}`)
  return icon
})

const style = computed(() => {
  if (props.size) return { width: `${props.size}px`, height: `${props.size}px` }
  return {}
})
</script>
